import React from "react"
// import Img from "gatsby-image"
import { Link, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import posed from "react-pose"
import Layout from "../components/layout"
// import styles from "../styles/blog-post.module.css"
import styles from "../styles/player.module.css"
import closeSVG from "../images/close.svg"
import upSVG from "../images/up-arrow.svg"

const Box = posed.div({
  closed: { y: "300px" },
  open: { y: "0px", transition: { duration: 600 } },
})

const Back = posed.div({
  hoverable: true,
  pressable: true,
  init: {
    scale: 1,
  },
  hover: {
    scale: 1.2,
  },
  press: {
    scale: 1.1,
  },
})

export default ({ data }) => {
  const post = data.markdownRemark
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Gaston Hummel</title>
      </Helmet>
      <Box className="container" initialPose="closed" pose="open">
        <div className="headerOther">
          <Back>
            <Link to={`/${post.frontmatter.type}`}>
              <img src={closeSVG} alt="back" height="32" width="32" />
            </Link>
          </Back>
          <h2 className="center nomargin">{post.frontmatter.title}</h2>
        </div>
        <div>
          <div
            className={styles.blogtext}
            dangerouslySetInnerHTML={{ __html: post.html }}
          />
        </div>
        <div className="headerOther">
          <Back>
            <Link to={`/${post.frontmatter.type}`}>
              <img src={upSVG} alt="back" height="40" width="40" />
            </Link>
          </Back>
        </div>
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        type
        video
      }
    }
  }
`
